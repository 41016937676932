import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-creative';
import 'swiper/css/navigation';
import { EffectCreative, Autoplay } from 'swiper/modules';
import LocationDetailLeft from './LocationDetailLeft';

const LocationDetailAbout = (props) => {
  const { data, icon2 ,icon3 } = props;
  const datas = props.data || [];
  
  const id = datas.length > 0 ? datas[0]?.id : null;

  const [imgglry, setImggalley] = useState([]);
  const [loading, setLoading] = useState(true);
  const [expanded, setExpanded] = useState(false);
  const [datta, setData] = useState([]);
  
 

  useEffect(() => {
    if (!id) return;

    const fetchImageGallery = async () => {
      try {
        const response = await fetch(`https://backend.corporateeventplanner.in/api/cyj-location-gallery/${id}`);
        const result = await response.json();
        setImggalley(result);
      } catch (error) {
        console.error('Fetching image gallery failed:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchImageGallery();
  }, [id]);

  useEffect(() => {
    if (!id) return;

    const fetchData = async () => {
      try {
        const response = await fetch(`https://backend.corporateeventplanner.in/api/cyj-location-other-data/${id}`);
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const result = await response.json();
        setData(result);
      } catch (error) {
        console.error('Fetching location data failed:', error);
      }
    };

    fetchData();
  }, [id]);

  const toggleReadMore = () => {
    setExpanded(!expanded);
  };

  const darkcolor = datas.length > 0 ? datas[0]?.darkColor : null;

  return (
    <section className="locationpageaboutSection" >
      <div className="innerlocationpageaboutSection">
        <div className="locationpageaboutContaner">
          <div className="locationpageaboutText">
            <h2  >{datas[0]?.heading}</h2>
            <p
              id="locationtextdata"
              className={expanded ? 'expanded' : ''}
              dangerouslySetInnerHTML={{ __html: datas[0]?.long_description || '' }}
            />
            <div className="readmorebtn" onClick={toggleReadMore}>
              <span></span>
              <i className={`fa-solid fa-circle-arrow-${expanded ? 'up' : 'down'}`}></i>
              <span></span>
            </div>
            <img 
              src={icon2} 
              className='icon2' 
              alt="Icon 2"
              />
          </div>

          <LocationDetailLeft datass={datas} icon3={icon3} />

          {datta.length > 0 && datta.some((i) => i.NearPlace?.length > 0) ? (
  <section className="places-visit">
    <div className="locationdetail-page-head">
      <h5 style={{ color: `${darkcolor}` }}>Places</h5>
      <h4>You Can Visit in {datas[0]?.location}</h4>
    </div>
    <div className="places-visit-container">
      {datta.map((i, index) =>
        i.NearPlace?.length > 0 ? (
          i.NearPlace.map((z) => (
            <div className="places" key={z.id || index}>
              <div className="places-img">
                <img
                  className="newimage"
                  src={`https://backend.corporateeventplanner.in/storage/location-gallery/${z.locationNearImage}`}
                  alt={z.nearPlaceHeading || 'Image'}
                />
              </div>
              <h4>{z.nearPlaceHeading || 'No heading'}</h4>
            </div>
          ))
        ) : null
      )}
    </div>
  </section>
) : null}


          {/* gallery */}
         {/* gallery */}
<div className="imagelocationpageabout" style={{ display: imgglry.length === 0 ? 'none' : 'block' }}>
  <div className="locationdetail-page-head">
    <h5 style={{ color: `${darkcolor}` }}>Recent</h5>
    <h4>Offsite Tours</h4>
  </div>
  {!loading ? (
    <div className="otherposion" id="otherposion">
      <div className="positionset">
        <Swiper
          grabCursor={true}
          effect={'creative'}
          creativeEffect={{
            prev: {
              shadow: true,
              translate: [0, 0, -400],
            },
            next: {
              translate: ['100%', 0, 0],
            },
          }}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          speed={1000}
          modules={[EffectCreative, Autoplay]}
          className="mySwiper innerimagelocationpageabout swiper-wrapper"
        >
          {imgglry.map((imgs, index) => (
            <SwiperSlide className="locationpageabotimage" key={index}>
              <img
                src={`https://backend.corporateeventplanner.in/storage/location-gallery/gallery/${imgs.location_gallery}`}
                alt={`Gallery ${index + 1}`}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  ) : null}
</div>

        </div>
      </div>
    </section>
  );
};

export default LocationDetailAbout;
