import React from 'react';
import './indexpageCss/IndexWhyChooseUs.css';
const IndexWhyChooseUs = () => {
  return (
    <section className="indexWhyChooseUsSection">
    <div className="innerindexWhyChooseUsSection">

        <div className="firstindexContanerWhycoose">
            <div className="imageindexwhychooseone">
                <div className="imagechooseone">
                    <img src="image/whyus.png" alt="" />
                </div>
            </div>
        </div>

        <div className="secoundindexContanerWhycoose">
            <div className="indewhyChooseText">
                <h2>What makes us unique?</h2>
                <p id="subheadingChoose">Making your events a blissful experience! </p>
                <p id="choosePara">Corporate Event Planner simplifies event planning by providing a comprehensive suite of corporate event services. Our offerings include offsite venue selection, event coordination, DJ and entertainment arrangements, outbound training programs, team-building activities, transportation logistics, and more.</p>
            </div>
            <div className="indexwhychooseusFeaturedbox">
                <div className="indexFeature">
                    <img src="image/icon/handshake.png" alt="" />
                    <h3>Team work </h3>
                    <p>Team of 20+ people </p>
                </div>

                <div className="indexFeature">
                    <img src="image/icon/balloon.png" alt="" />
                    <h3>Well-equipped venues </h3>
                    <p>Make memories</p>
                </div>

                <div className="indexFeature">
                    <img src="image/icon/cheers.png" alt="" />
                    <h3>Well-balanced atmosphere</h3>
                    <p>Great ideas</p>
                </div>

                <div className="indexFeature">
                    <img src="image/icon/party.png" alt="" />
                    <h3>Impeccable amenities</h3>
                    <p>We make every moment amazing at your event!</p>
                </div>

                <div className="indexFeature">
                    <img src="image/icon/mobile.png" alt="" />
                    <h3>24/7 availability</h3>
                    <p>Anytime Anywhere</p>
                </div>

                <div className="indexFeature">
                    <img src="image/icon/idea.png" alt="" />
                    <h3>Amazing Ideas</h3>
                    <p>We have ample of ideas</p>
                </div>


            </div>
        </div>
    </div>
</section>

  )
}

export default IndexWhyChooseUs
