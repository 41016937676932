import React, { useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';

import { Pagination, Autoplay } from 'swiper/modules';
import './indexpageCss/IndexTestimonial.css';
const IndexTestimonial = () => {
    let slidesPerView;
    var screenWidth = window.innerWidth;

    if (screenWidth >= 1050) {
      slidesPerView = 4;
    } else if (screenWidth >= 750) {
        slidesPerView = 3;
    } else if (screenWidth >= 650) {
        slidesPerView = 2;
    } else {
        slidesPerView = 1;
    }


  return (
    <section className="testimonialSectionSlider">
    <div className="innerTestimonialSlider">

        <div className="Heading">
            <span className="topheading">Our Satisfied</span>
            <div className="sbheadings">
                <p>WHAT</p>
                <h2>Clients</h2>
                <p>SAY ABOUT US</p>
            </div>
        </div>

        <div className="testimaincontanerslide">
        <Swiper
        slidesPerView={slidesPerView}
        spaceBetween={30}
        loop={true}
        autoplay={{
            delay: 2500,
            disableOnInteraction: false,
        }}
        speed={1000}
        modules={[Autoplay]}
        className="mySwiper testimonialSlideBox">
            {/* testimonial Cards start */}
            <SwiperSlide className="slideTesimonial">
                <div className="messageBox">
                <img className='goggle-review' src='image/icon/google.png'/>
                    <p>Very good experience in handling large groups. Had done an event with 100+ ppl and they delivered with excellent transport services, good venues, lodging and accomodation. Thanks Jasvinder and Renu for your excellent support and coordination.</p>
                    <span className="arrowIconMessg"></span>
                </div>
                <div className="clientprofilebox">
                    <div className="profile">
                        <p>R</p>
                    </div>
                    <h3>Rajesh Nair</h3>
                    
                </div>
                
            </SwiperSlide>


            <SwiperSlide className="slideTesimonial">
                <div className="messageBox">
                <img className='goggle-review' src='image/icon/google.png'/>
                    <p>One of the smoothest corporate offsite experience, organised by CYJ team.. Thank you Jaswender Ji and Renu Ji for all the support and for making this really fun and exciting..</p>
                    <span className="arrowIconMessg"></span>
                </div>
                <div className="clientprofilebox">
                    <div className="profile">
                     <p>A</p>
                    </div>
                    <h3>Alla sravani</h3>
                </div>
            </SwiperSlide>


           

            <SwiperSlide className="slideTesimonial">
                <div className="messageBox"><img className='goggle-review' src='image/icon/google.png'/>
                    <p>We used CYJ's services to organize an offsite for our team of 60 people recently. Their team is very professional and they delivered everything they promised. The team was prompt and proactive with everything that was planned. Certainly recommend team CYJ for any or every event needs.</p>
                    <span className="arrowIconMessg"></span>
                </div>
                <div className="clientprofilebox">
                    <div className="profile">
                        <p>B</p>
                    </div>
                    <h3>Bikramjit Sethi</h3>
                </div>
            </SwiperSlide>

            <SwiperSlide className="slideTesimonial">
                <div className="messageBox"><img className='goggle-review' src='image/icon/google.png'/>
                    <p>Thank you CYJ team for amazing corporate offsite, you have been patiently dealing with our requests... Thank you for the wonderful time.</p>
                    <span className="arrowIconMessg"></span>
                </div>
                <div className="clientprofilebox">
                    <div className="profile">
                        <p>R</p>
                    </div>
                    <h3>Roshni Chugh</h3>
                </div>
            </SwiperSlide>

            <SwiperSlide className="slideTesimonial">
                <div className="messageBox"><img className='goggle-review' src='image/icon/google.png'/>
                    <p>We have had the opportunity last week to get our Team building event for 40 people hosted by CYJ and honestly it was fun.
                    Right from their content and activities, everything was well planned and executed efficiently. They also helped to capture the fun moments and shared them later at no additional cost. I will recommend them for any kind of corporate offsite events.</p>
                    <span className="arrowIconMessg"></span>
                </div>
                <div className="clientprofilebox">
                    <div className="profile">
                        <p>V</p>
                    </div>
                    <h3>Vivek Gupta</h3>
                </div>
            </SwiperSlide>


            <SwiperSlide className="slideTesimonial swiper-slide">
                <div className="messageBox"><img className='goggle-review' src='image/icon/google.png'/>
                    <p>Excellent Service and hospitality are really good, The planning and implementation of the outing were well managed in Ramnagar. Everyone really enjoyed..</p>
                    <span className="arrowIconMessg"></span>
                </div>
                <div className="clientprofilebox">
                    <div className="profile">
                        <p>D</p>
                    </div>
                    <h3>Deepak Sharma</h3>
                </div>
            </SwiperSlide>

            <SwiperSlide className="slideTesimonial swiper-slide">
                <div className="messageBox"><img className='goggle-review' src='image/icon/google.png'/>
                    <p>It was a wonderful experience.  I enjoyed the trip organized by Comfort throughout starting from the time of boarding the bus, staying in a spacious room of well reputed and fabulous hotel with its amenities.  The activities provided were praiseworthy.  I enjoyed the food in the hotel...  I will have no hesitation in recommending Comfort to anyone who is looking for a comfortable trip.</p>
                    <span className="arrowIconMessg"></span>
                </div>
                <div className="clientprofilebox">
                    <div className="profile">
                        <p>R</p>
                    </div>
                    <h3>Rajendran P</h3>
                </div>
            </SwiperSlide>

        </Swiper>
             {/* testimonial Cards End */}

        </div>
    </div>
</section>
  )
}

export default IndexTestimonial
