import React, {useEffect, useState, useRef} from 'react';
import Neardelhiresort from './Neardelhiresort'
import './Nearall.css';
import IndexTopClient from '../IndexPage/IndexTopClient';
import IndexTestimonial from '../IndexPage/IndexTestimonial';


  const Neardelhi = () => {
    const [isExpanded, setIsExpanded] = useState(true);

    const toggleContent = () => {
        setIsExpanded(!isExpanded);
    };

    const videoRef = useRef(null);

  useEffect(() => {
    const video = videoRef.current;
  }, []);


  return (
    <div>
      <div className="neardelhi-topbanner">
          <div className="left-content">
           <img src='image/text-head2.png'/>
            <p>
            Corporate Offsites offer a refreshing escape for tired minds. Taking a weekend off for a team outing is a great way to boost employee morale. Balancing work with fun is essential, and these outings help reduce stress while providing an opportunity for deeper relaxation.
            </p>
          </div>
          <div className="right-content">
            <div className="video-bubble">
              <video className="bubble-video" ref={videoRef} autoPlay muted loop>
                <source src="/videos/neardelhi.mp4" type="video/mp4" />
              </video>
              <h2>Near Delhi</h2>
            </div>
          </div>
      </div>
      <div className='neardelhiresorts-section'>
        <h2>Discover the best venues for corporate offsite Near Delhi</h2>
         <p className={`content ${isExpanded ? 'expanded' : ''}`}>
         In today’s time, having a friendly atmosphere in the office is equally important as having a job. It plays a major role in making a company’s identity. One of the best ways where you can have most of the time when a company decides to host corporate outings whether a day trip or an offsite retreat or a team-building excursion. In order to create a happy atmosphere, corporate offsite tours are really important. Apart from this, for the growth of a company, the office should have a nice environment, so the employees feel happy when they enter the office. Corporate Outings is one of the best ways to encourage the employees of your office and you can easily take them to some destination. The fact is that whether small or big, the corporate team outings are happening mostly in all the companies now as everyone knows how important it is.<br></br><br></br>

There are many benefits of hosting corporate offsites as they provide employees with an opportunity to build stronger relationships outside the work environment and also lets you improve the bond between the teams after they resume office again. This will not only make their minds fresh but also make them a better person as an individual. There are a lot of team-building activities that you can include to make their bonding within the team better. When employees from all levels of the organization engage in team-building activities, it fosters a sense of equality and encourages open communication, which can ultimately improve workplace dynamics.<br></br><br></br>

These corporate offsites are also best for boosting morale. After long periods of intense work, employees often feel drained and disinterested in everything and that's when you have to realise that you can go for it. Having a break from mental stress can really make your employees happy and they will be more energized for better job satisfaction. These often makes the employees' mind fresh so that when they return to the office again, they will be able to figure things out in every situation that occurs related to both personal and professional life.<br></br><br></br>

Another key benefit for hosting the corporate outings is the development of leadership and communication skills that one develops while experiencing the same. Not only does this develop an individual professionally but also personally and lets them think out of the box ideas for their growth. <br></br>
For organizations looking to host successful corporate outings, Delhi offers a wide range of nearby destinations. These places offer the perfect combination of recreation, relaxation, and team-building opportunities. Neemrana Fort Palace, for example, is a heritage hotel located about 122 km from Delhi. It offers a unique setting for a corporate outing with its vast grounds, luxurious rooms, and outdoor activities. <br></br><br></br>

Corporate Conference Venues Near Delhi are also plentiful, and many of these venues offer a combination of meeting areas and recreational facilities. Whether it’s a high-level board meeting, a strategy session, or an annual corporate gathering, there are numerous options one of the best places to host the Corporate Offsite Near Delhi. All the venues will be perfectly equipped with all high-end amenities for all your business needs. The facilities like high-speed internet, audio-visual equipment, video conferencing, marker, whiteboards, Projectors and much more you can find here. <br></br><br></br>

Apart from everything, in corporate outings, board meetings, not only an experience is something you will have but it is also great for creating a space where creativity, communication and collaboration takes place and it can actually flourish. You can actually have the best time with your employees without making it look like a corporate event because it will surely make your bond better with each of your employee as you get time to spend with them. Whether it’s a day trip, a weekend retreat, or an extended offsite, the right destination can provide the perfect balance of business and pleasure, fostering a positive and productive work culture.<br></br><br></br>

Here are the Top Corporate Offsite Venues for Team Outings Near Delhi for an unforgettable and relaxing outing.


            </p>
            {/* <button onClick={toggleContent} className="read-more-btn">
                {isExpanded ? 'Read Less ↑' : 'Read More ↓'}
            </button> */}
            <div onClick={toggleContent} className="read-more-btn">
              <span></span>
               <i className={`fa-solid fa-circle-arrow-${isExpanded ? 'up' : 'down'}`}></i>
              <span></span>
            </div>
           
        <Neardelhiresort/>

        <p className='bottom-neardelhi-content'>
                

              
            </p>
      </div>
      <div style={{backgroundColor:'#2e073f', paddingTop:'1rem',
        backgroundImage: 'linear-gradient(150deg, #2E073F, #76308d, #2E073F)'
      }} >
        <IndexTestimonial/>
      </div>
      <IndexTopClient/>
    </div>
    
  )
}

export default Neardelhi
