import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import '../IndexPage/indexpageCss/IndexService.css';
import PageBanner from '../../components/PageBanner';
import IndexTopClient from '../IndexPage/IndexTopClient';
import IndexTestimonial from '../IndexPage/IndexTestimonial';

const AllServices = () => {

    const isMobile = window.innerWidth <= 768;

    const [serdta, setServdata]=useState([]);
    useEffect(()=>{
        const servicedata = async()=>{
            try{
                const result = await fetch('https://backend.corporateeventplanner.in/api/cyj-service');
                const data = await result.json();
                setServdata(data);
            }catch(error){
                console.log(error);
            }
        }
        servicedata();
    }, []);


  return (
<>
    <PageBanner image="image/image5.jpg" heading="All Services" pageLink="/all-service" />
     <section className="serviceSection" >
    {/* <div className="Heading">
        <span className="topheading">Our</span>
        <div className="sbheadings">
            <p>Lorem ipsum dolor</p>
            <h2>Services</h2>
            <p>Lorem ipsum dolor</p>
        </div>
    </div> */}
   
    <div className="innerServiceSection">
    <h2
  style={{
    textAlign: 'center',
    width: '85%',
    margin: 'auto',
    color: '#2E073F',
    fontSize: isMobile ? '28px' : '42px',
    textTransform: 'uppercase',
    marginBottom: '-2rem',
    marginTop: '1rem',
  }}
>A sneak peak of our Corporate Services</h2>
        <div className="servicebox">

            {/* service Cards Start */}
           {/* service Cards Start */}
           {
    serdta
    .filter((i)=>i.status === 1)
    .map((serItem, index) => (
        <div className="serviceCards" key={index} >
            {serItem.image
                .filter(i => i.icon !== undefined)  // Filter out undefined icons
                .map((i, iIndex) => (  // Map over the filtered array
                    <div className="serviceimage" key={iIndex}>
                        <img 
                            src={`https://backend.corporateeventplanner.in/storage/service_gallery/${i.icon}`} 
                            alt={serItem.service} 
                        />

<div className="serviceText">
                <h3>{serItem.service}</h3>
                <p>{serItem.small_description}</p>
                <NavLink to={"../"+serItem.service_url}>Read More</NavLink>
            </div>
                    </div>
                ))
            }
            
          
        </div>
    ))
}

           

           {/* service Cards end */}

        </div>
    </div>
   
</section>

<div style={{backgroundColor:'#2e073f', paddingTop:'1rem'}} >
<IndexTestimonial/>
</div>
<IndexTopClient/>

</>
  )
}

export default AllServices
