import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import './Nearallresort.css';
import axios from 'axios';
import Swal from 'sweetalert2';
import { Autoplay, Navigation } from 'swiper/modules';

const Nearmumbairesort = () => {
    const [resorts, setResorts] = useState([]);
    const [resortGalleries, setResortGalleries] = useState({});
    const [selectedResort, setSelectedResort] = useState(null);
    // const [visibleCount, setVisibleCount] = useState(18);
    const [formData, setFormData] = useState({
        name: '',
        phone: '', 
        email: '',
        message: '',
        pageUrl: window.location.href,
    });
    const [errors, setError] = useState('');

    const locationIds = [56, 57, 58, 59, 27, 26, 23];

    useEffect(() => {
        fetch('https://backend.corporateeventplanner.in/api/cyj-resorts')
            .then((response) => response.json())
            .then((result) => {
                const filteredResorts = result.filter((resort) =>
                    locationIds.includes(resort.location_id)
                );
                setResorts(filteredResorts);

                filteredResorts.forEach((resort) => {
                    fetch(
                        `https://backend.corporateeventplanner.in/api/cyj-resorts-gallery/${resort.id}`
                    )
                        .then((response) => response.json())
                        .then((gallery) => {
                            setResortGalleries((prev) => ({
                                ...prev,
                                [resort.id]: gallery,
                            }));
                        })
                        .catch((error) =>
                            console.log('Error fetching resort gallery:', error)
                        );
                });
            })
            .catch((error) => console.log('Error fetching resorts:', error));
    }, []);

    const handlePopupOpen = (resort) => {
        setSelectedResort(resort);
        setError('');
        setFormData({
            ...formData,
            name: '',
            phone: '', 
            email: '',
            message: '',
        });
    };

    const handlePopupClose = () => {
        setSelectedResort(null);
        setError('');
    };

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const website_name = 'Corporate Planner';
    const indiaDateTime = new Date().toLocaleString('en-IN', {
      timeZone: 'Asia/Kolkata',
      hour12: false,            
      weekday: 'long',         
      year: 'numeric',          
      month: 'long',        
      day: 'numeric',       
      hour: '2-digit',         
      minute: '2-digit',       
      second: '2-digit'   
    });

    const formattedDateTime = indiaDateTime.replace(/,/g, ''); 

    let contact = '';
    if (formData['name'] !== '') contact += `Name:-${formData['name']}, `;
    if (formData['phone'] !== '') contact += `Mobile:-${formData['phone']}, `;
    if (formData['email'] !== '') contact += `Email:-${formData['email']}, `;
    contact += `Resorts:-${selectedResort ? selectedResort.resorts_name : ''}, `;
    contact += `Location:-${selectedResort ? selectedResort.sub_location : ''}, `;
    if (formData['pageUrl'] !== '') contact += `Url:-${formData['pageUrl']}, `;
    contact += `Date:-${formattedDateTime}`;
    const messages = formData['message'];

    const formdta = {
      website_name: website_name,
      contact: contact,
      message: messages
    };



    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(
                'https://leads.hotelstourandtravel.com/api/cyj-leads-insert', formdta);
            Swal.fire({
                icon: 'success',
                title: 'Success',
                text: response.data.message,
            }).then(() => {
                handlePopupClose();
            });
        } catch (error) {
            setError(error.response.data);
        }
    };

    // const handleShowMore = () => {
    //     setVisibleCount((prev) => prev + 18);
    // };

    return (
        <div className="nearResortsContainer">
             <div className="lokreortheadings">
              <h2> Resorts Near Mumbai for Corporate Events</h2>  
            </div>
            <div className="nearresortsFlexContainer">
                {resorts.map((resort) => (
                    <div className="nearresortCard" key={resort.id}>
                        <div className="nearresortGallery">
                        <img
                                src={`https://backend.corporateeventplanner.in/storage/resorts-gallery/${resort.resorts_banner}`}
                                alt={resort.resorts_name}
                            />
                            {/* <Swiper
                                centeredSlides={true}
                                rewind={true}
                                navigation={true}
                                autoplay={{
                                    delay: 2500,
                                    disableOnInteraction: false,
                                }}
                                speed={1000}
                                modules={[Autoplay, Navigation]}
                                className="mySwiper nearresortGalleryslider"
                            >
                                {resortGalleries[resort.id]?.map((img) => (
                                    <SwiperSlide
                                        className="nearresortsGallery"
                                        key={img.id}
                                    >
                                        <img
                                            src={`https://backend.corporateeventplanner.in/storage/resorts-gallery/gallery/${img.resorts_gallery}`}
                                            alt={resort.resorts_name}
                                        />
                                    </SwiperSlide>
                                ))}
                            </Swiper> */}
                            <span>{resort.sub_location}</span>
                        </div>
                        <div className="nearresortDetails">
                            <h3>{resort.resorts_name}</h3>
                            <h5>{resort.sub_location}</h5>
                            <p className='shortdecresort' dangerouslySetInnerHTML={{__html:resort.long_description}}></p>
                            <div className="contact-resort">
                                <button onClick={() => handlePopupOpen(resort)}>
                                    View Detail
                                </button>
                                <a href="tel:8130781111"><i className="fa-solid fa-phone"> </i>  Call Us</a>
                            </div>
                        </div>
                    </div>
                ))}
            </div>

            {/* {visibleCount < resorts.length && (
                <button className="showMoreButton" onClick={handleShowMore}>
                    View More
                </button>
            )} */}

            {selectedResort && (
                <div className="popupOverlay" onClick={handlePopupClose}>
                    <div
                        className="popupContent"
                        onClick={(e) => e.stopPropagation()}
                    >
                        <button className='nearbyform-close' onClick={handlePopupClose}>✖</button>
                        <div className='nearbyform-left'>
                        
                        <Swiper
                                centeredSlides={true}
                                rewind={true}
                                navigation={true}
                                autoplay={{
                                    delay: 2500,
                                    disableOnInteraction: false,
                                }}
                                speed={1000}
                                modules={[Autoplay, Navigation]}
                                className="mySwiper nearresortGalleryslider"
                            >
                                {resortGalleries[selectedResort.id]?.map((img) => (
                                    <SwiperSlide
                                        className="nearresortsGallery"
                                        key={img.id}
                                    >
                                        <img
                                            src={`https://backend.corporateeventplanner.in/storage/resorts-gallery/gallery/${img.resorts_gallery}`}
                                            alt={img.resorts_name}
                                        />
                                    </SwiperSlide>
                                ))}
                               <h4>{selectedResort.resorts_name}</h4>
                            </Swiper>
                        </div>
                       
                       <div className='nearby-formright'>
                       <h3>We'd Love to Hear from You!</h3>
                       
                        <form onSubmit={handleSubmit} className="popupForm">
                            <label>Name:</label>
                            <input
                                type="text"
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                                placeholder="Enter your name"
                            />
                            <p style={{ color: 'red' }}>{errors.name}</p>

                            <label>Phone:</label> 
                                <input
                                    type="text"
                                    name="phone"
                                    value={formData.phone}
                                    onChange={handleChange}
                                    placeholder="Enter your phone number"
                                />
                                <p style={{ color: 'red' }}>{errors.phone}</p>

                            <label>Email:</label>
                            <input
                                type="email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                placeholder="Enter your email"
                            />
                            <p style={{ color: 'red' }}>{errors.email}</p>

                            <label>Message:</label>
                            <textarea
                                name="message"
                                value={formData.message}
                                onChange={handleChange}
                                placeholder="Your message here..."
                            />
                            <p style={{ color: 'red' }}>{errors.message}</p>

                            <button className='nearby-submit' type="submit">Submit</button>
                        </form>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Nearmumbairesort;
