import React, { useEffect } from 'react'
import IndexTopClient from './IndexTopClient';
import IndexAbout from './IndexAbout';
import IndexService from './IndexService';
import IndexLocation from './IndexLocation';
import IndexWhyChooseUs from './IndexWhyChooseUs';
import IndexGallery from './IndexGallery';
import IndexOurUps from './IndexOurUps';
import IndexTestimonial from './IndexTestimonial';
import './indexpageCss/IndexDevice.css';
import MetaTag from '../../components/MetaTag';
import Indexnewbanner from './Indexnewbanner';
const Index = () => {

  return (
    <div style={{backgroundColor:"#2E073F"}}>
    <MetaTag noindex={true} titles="Index title" metakeywords="Index keywords in gurgaon" metaDescriptions="Index Description 1" hrefLinks={window.location.href}/>
    {/* <IndexBanner /> */}
    <Indexnewbanner/>
    {/* <IndexTopClient /> */}
    <IndexAbout />
    <IndexService />
    <IndexLocation />
    <IndexWhyChooseUs />
    <IndexGallery />
    <div className='usp-index' >
      <IndexOurUps />
    </div>
    <IndexTestimonial />

    </div>
  )
}

export default Index
