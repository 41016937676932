import React, {useEffect, useState, useRef} from 'react';
import './Nearall.css';
import Nearmumbairesort from './Nearmumbairesort';
import IndexTopClient from '../IndexPage/IndexTopClient';
import IndexTestimonial from '../IndexPage/IndexTestimonial';

  const Nearmumbai = () => {
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleContent = () => {
        setIsExpanded(!isExpanded);
    };

    const videoRef = useRef(null);

  useEffect(() => {
    const video = videoRef.current;
  }, []);


  return (
    <div>
      <div className="neardelhi-topbanner">
          <div className="left-content">
           <img src='image/text-head2.png'/>
            <p>
            Corporate Offsites offer a refreshing escape for tired minds. Taking a weekend off for a team outing is a great way to boost employee morale. Balancing work with fun is essential, and these outings help reduce stress while providing an opportunity for deeper relaxation.
            </p>
          </div>
          <div className="right-content">
            <div className="video-bubble">
            <video className="bubble-video" ref={videoRef} autoPlay muted loop>
                <source src="/videos/nearmumbai.mp4" type="video/mp4" />
              </video>
              <h2>Near Mumbai</h2>
            </div>
          </div>
      </div>
      <div className='neardelhiresorts-section'>
        <h2>PLAN YOUR NEXT CORPORATE OFFSITE AT THE TOP VENUES NEAR MUMBAI AND PUNE</h2>
         <p className={`content ${isExpanded ? 'expanded' : ''}`}>
         With the help of corporate offsites, you will be able to focus more on strengthening the team bonding with each other. This will not only make the bonding better with each other but you can also engage with them and get to know about them in person. These offsites are typically held outside the usual work environment so that the whole team can disconnect themselves from the work stress and head to some amazing time together with other teammates. By stepping away from the pressure of daily work, companies nowadays help their employees to get close to nature and let them understand their other colleagues. Whether it’s for brainstorming sessions, leadership workshops, or a relaxing retreat, a corporate offsite will surely improve and drive long-term success.<br></br><br></br>
        Mumbai and Pune are the best corporate sectors in Maharashtra that serve as excellent bases for corporate offsite events as they are developed when it comes to making the most of your corporate events amazing in one place. Together these cities offer ample corporate offsite venues to the people looking out for the same. The proximity of both cities to hill stations, beaches, and vineyards makes them an ultimate destination where teams can relax and enjoy the event simultaneously.<br></br><br></br>
        Apart from this, there are a lot of benefits to hosting corporate offsite. First and foremost, it provides a change of place and most importantly no one has to do the work. When they get out of the office cubicles, they find peace and after coming from the event, they will be more prone to give you new ideas and strategies that you might be expecting from them right now. This happens as you have to break the monotony so that they don’t feel bored. Additionally, the relaxed atmosphere promotes creativity and innovation, which can translate into better problem-solving when the team returns to work. Finally, corporate offsites can improve morale, motivation, and overall job satisfaction, as employees appreciate the time spent outside the office, which helps them feel valued and engaged.<br></br><br></br>
        For those who are looking for a perfect Corporate Offsite Near Mumbai/Pune, CYJ is one of the best corporate offsite planners when it comes to making your event amazing, we stand throughout your journey. Our team has years of experience in aligning the best for you in terms of corporate offsite events. We ensure that you do not have to worry about anything as we will take care of your tiniest requirements from venue selection to team building activities, everything is curated for you.<br></br><br></br>
        Plan Your Next Team Outing at the Best Corporate Offsite Venues Near Mumbai and Pune for a Unique and Relaxing Experience.

            </p>
            {/* <button onClick={toggleContent} className="read-more-btn">
                {isExpanded ? 'Read Less ↑' : 'Read More ↓'}
            </button> */}
            <div onClick={toggleContent} className="read-more-btn">
              <span></span>
               <i className={`fa-solid fa-circle-arrow-${isExpanded ? 'up' : 'down'}`}></i>
              <span></span>
            </div>
        <Nearmumbairesort/>

        <p className='bottom-neardelhi-content'>
                
            </p>
      </div>
      <div style={{backgroundColor:'#2e073f', paddingTop:'1rem',
        backgroundImage: 'linear-gradient(150deg, #2E073F, #76308d, #2E073F)'
      }} >
        <IndexTestimonial/>
      </div>
      <IndexTopClient/>
    </div>
  )
}

export default Nearmumbai
