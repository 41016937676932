import React from 'react';
import { NavLink } from 'react-router-dom';
const ContactBanner = () => {
  return (
    <section className="pagesBannerDesignBox">
    <div className="innerpagesBannerDesignBox">
        <div className="imagepagesBox">
            <div className="imagespages">
                <img src="image/image5.jpg" alt="" />
            </div>
        </div>
    </div>
    <div className="pagesesText">
        <h1>Contact Us</h1>
       
    </div>
</section>
  )
}

export default ContactBanner
