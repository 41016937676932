import React, {useEffect, useState, useRef} from 'react';
import './indexpageCss/IndexOurUps.css';


const IndexOurUps = () => {
    


const videoRef = useRef(null);

  useEffect(() => {
    const video = videoRef.current;
  }, []);




    let [selectid, setSelectId]=useState(-1);

    let shoUpsFeature=(idx)=>{
        setSelectId(idx === selectid ? 1 : idx); 
    }


  return (
    <section className="indexupsSection">
    <div className="innerindexupsSection">
        <div className="indexupsvideoSection">
        <video ref={videoRef} autoPlay muted loop>
            <source src="/videos/video4.mp4" type="video/mp4" />
        </video>
        </div>
        <div className="indexupstextSection">
            <h2>Our USP</h2>
            <p id="upsSubheading">We take your corporate offsite event to new heights!</p>
            <p id="upspara">We specialize in organizing a wide range of corporate events, from intimate gatherings to large-scale conferences. No matter the size or scope, we ensure every detail is managed with precision and professionalism, delivering an impactful experience for your team or clients. Our offerings are tailored to meet the unique needs of your event, making it seamless and successful. Our dedicated team ensures seamless execution, from logistics to innovative team-building solutions, creating impactful and enjoyable experiences for companies of all sizes. </p>

            <div className="ourupsFeatureBox">

                <div className="upsbox">
                    <div className="indourups" onClick={()=>shoUpsFeature(0)}>
                        <div className="upshead">
                            <span>➤ </span>
                            <h3>High-End Equipments</h3>
                        </div>
                        <i className="fa-solid fa-circle-arrow-down upsarro"></i>
                    </div>
                    <ul className={`upsfetrd ${selectid === 0 ? '' : 'uactive'}`}>
                        <li><i className="fa-solid fa-circle-check"></i> Digital Audio/Visual Projectors</li>
                        <li><i className="fa-solid fa-circle-check"></i> High Definition Video Cameras</li>
                        <li><i className="fa-solid fa-circle-check"></i> Polycom Speakerphones</li>
                        <li><i className="fa-solid fa-circle-check"></i> HD Audio Quality for better communication</li>
                        <li><i className="fa-solid fa-circle-check"></i> Multi-monitor support</li>
                        <li><i className="fa-solid fa-circle-check"></i> Interoperable with VoIP services</li>
                        <li><i className="fa-solid fa-circle-check"></i> Self-diagnostic & easy troubleshooting</li>
                        <li><i className="fa-solid fa-circle-check"></i> Interactive Display Boards</li>
                    </ul>
                </div>


                <div className="upsbox">
                    <div className="indourups" onClick={()=>shoUpsFeature(1)}>
                        <div className="upshead">
                            <span>➤</span>
                            <h3>Entertainment & Engagements</h3>
                        </div>
                        <i className="fa-solid fa-circle-arrow-down upsarro"></i>
                    </div>
                    <ul className={`upsfetrd ${selectid === 1 ? '' : 'uactive'}`}>
                        <li><i className="fa-solid fa-circle-check"></i> Digital Audio/Visual Projectors</li>
                        <li><i className="fa-solid fa-circle-check"></i> High Definition Video Cameras</li>
                        <li><i className="fa-solid fa-circle-check"></i> Polycom Speakerphones</li>
                        <li><i className="fa-solid fa-circle-check"></i> HD Audio Quality for better communication</li>
                        <li><i className="fa-solid fa-circle-check"></i> Multi-monitor support</li>
                        <li><i className="fa-solid fa-circle-check"></i> Interoperable with VoIP services</li>
                        <li><i className="fa-solid fa-circle-check"></i> Self-diagnostic & easy troubleshooting</li>
                        <li><i className="fa-solid fa-circle-check"></i> Interactive Display Boards</li>
                    </ul>
                </div>

                <div className="upsbox">
                    <div className="indourups" onClick={()=>shoUpsFeature(2)}>
                        <div className="upshead">
                            <span>➤</span>
                            <h3>Logistic Services</h3>
                        </div>
                        <i className="fa-solid fa-circle-arrow-down upsarro"></i>
                    </div>
                    <ul className={`upsfetrd ${selectid === 2 ? '' : 'uactive'}`}>
                        <li><i className="fa-solid fa-circle-check"></i> Digital Audio/Visual Projectors</li>
                        <li><i className="fa-solid fa-circle-check"></i> High Definition Video Cameras</li>
                        <li><i className="fa-solid fa-circle-check"></i> Polycom Speakerphones</li>
                        <li><i className="fa-solid fa-circle-check"></i> HD Audio Quality for better communication</li>
                        <li><i className="fa-solid fa-circle-check"></i> Multi-monitor support</li>
                        <li><i className="fa-solid fa-circle-check"></i> Interoperable with VoIP services</li>
                        <li><i className="fa-solid fa-circle-check"></i> Self-diagnostic & easy troubleshooting</li>
                        <li><i className="fa-solid fa-circle-check"></i> Interactive Display Boards</li>
                    </ul>
                </div>

                <div className="upsbox">
                    <div className="indourups" onClick={()=>shoUpsFeature(3)}>
                        <div className="upshead">
                            <span>➤</span>
                            <h3>Outing Management</h3>
                        </div>
                        <i className="fa-solid fa-circle-arrow-down upsarro"></i>
                    </div>
                    <ul className={`upsfetrd ${selectid === 3 ? '' : 'uactive'}`}>
                        <li><i className="fa-solid fa-circle-check"></i> Digital Audio/Visual Projectors</li>
                        <li><i className="fa-solid fa-circle-check"></i> High Definition Video Cameras</li>
                        <li><i className="fa-solid fa-circle-check"></i> Polycom Speakerphones</li>
                        <li><i className="fa-solid fa-circle-check"></i> HD Audio Quality for better communication</li>
                        <li><i className="fa-solid fa-circle-check"></i> Multi-monitor support</li>
                        <li><i className="fa-solid fa-circle-check"></i> Interoperable with VoIP services</li>
                        <li><i className="fa-solid fa-circle-check"></i> Self-diagnostic & easy troubleshooting</li>
                        <li><i className="fa-solid fa-circle-check"></i> Interactive Display Boards</li>
                    </ul>
                </div>

            </div>
        </div>
    </div>
</section>
  )
}

export default IndexOurUps;
