import React from 'react'
import './LocationPageCss/LocationDetail.css';
import LocationDetailLeft from './LocationDetailLeft';
import LocationDetailRight from './LocationDetailRight';
import IndexLocation from '../IndexPage/IndexLocation';
const LocationDetailSection = (props) => {
  const { icon4 } = props;
  const dta = props.data;
  let vallok = () => {
    return dta.map((x) => x.location).join(', ');
  }
  
  
  return (
    <div>
<section class="locationpageleftrightSectioon">
    <img 
      src={icon4} 
      className='icon4' 
      alt="Icon 4"
    />
    <div class="innerleftrightsection">
   
    {/* <LocationDetailRight  datass={dta} /> */}
    <IndexLocation lokdt={vallok()}/>
    </div>
</section>
    </div>
  )
}

export default LocationDetailSection
