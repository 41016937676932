import React from 'react';
import './AboutPageCss/AboutPageClient.css';
import GalleryClient from '../GalleryPage/GalleryClient';
import IndexTopClient from '../IndexPage/IndexTopClient';
const AboutPageClient = () => {
  return (
    <section className="aboutpageclientSection">
    <div className="inneraboutclient">
        <div className="Heading">
            <span className="topheading">Our</span>
            <div className="sbheadings">
                <p>CYJ IS THE</p>
                <h2>Clients</h2>
                <p>BEHIND YOUR EVENT</p>
            </div>
        </div>

        <div className="aboutclientImageBox">
           {/* <GalleryClient /> */}
           <IndexTopClient />
        </div>
        {/* <NavLink to="gallery" id="abcxbtn">View All Clients <i className="fa-solid fa-arrow-right-long"></i></NavLink> */}
    </div>
</section>

  )
}

export default AboutPageClient;
