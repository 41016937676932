import React from 'react';
import './AboutPageCss/AboutPageVission.css';
const AboutPageVission = () => {
  return (
    <section className="vissionSection">
    <div className="innervissionSection">
        <div className="Heading">
            <span className="topheading">Our</span>
            <div className="sbheadings">
                <p>CYJ IS THE</p>
                <h2>Values</h2>
                <p>BEHIND YOUR EVENT</p>
            </div>
        </div>

        <div className="missionvisionbox">
            <div className="goalbox">
                <div className="goalhead">
                    <i className="fa-solid fa-bullseye"></i>
                    <h3>Mission</h3>
                </div>
                <p>Our mission at CYJ is to create exceptional corporate events that inspire, engage, and connect. We are committed to delivering flawless experiences by providing innovative solutions, cutting-edge technology, and seamless logistics, all while ensuring personalized service that exceeds our clients' expectations. Through our expertise and dedication, we help organizations achieve their event goals and leave a lasting impact on their audiences.</p>
            </div>
            <div className="goalbox">
                <div className="goalhead">
                <i className="fa-solid fa-eye"></i>
                <h3>Vision</h3>
                </div>
                <p>To be the leading corporate event planner, known for transforming ideas into extraordinary experiences. We envision a future where every corporate event we curate is a catalyst for meaningful connections, success, and growth. By continuously embracing innovation and creativity, we aim to set new standards in the event planning industry, becoming the trusted partner for businesses worldwide.</p>
            </div>

            <div className="goalbox">
                <div className="goalhead">
                <i className="fa-solid fa-gem"></i>
                <h3>Values</h3>
                </div>
                <p>At CYJ, our values are at the core of everything we do. We are committed to excellence, ensuring every event is meticulously planned and executed to the highest standards, delivering exceptional results. Innovation drives us to explore new ideas, cutting-edge technology, and creative solutions to keep our events dynamic and engaging. We believe in the power of collaboration, working closely with our clients to understand their vision and transform it into a memorable experience.</p>
            </div>
        </div>
    </div>
</section>


  )
}

export default AboutPageVission
