import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

const MobileNavBar = () => {

useEffect(()=>{
  const links = document.querySelectorAll('a');
  links.forEach(link=>{
    link.addEventListener('click', scrollTop);
  });

  return ()=>{
    links.forEach(link=>{
      link.removeEventListener('click', scrollTop);
    });
  }

},[]);
const scrollTop =()=>{
  window.scrollTo({
    top:0,
    behavior: 'smooth'
  });
}



  return (
    <div className="mobileMenuheader">
    <i className="fa-solid fa-xmark mbcross"></i>
    <div className="innermobileMenuheader">
        <div className="deviceLogobox">
            <img src="image/whitelogo.png" alt="" />
        </div>
        <ul class="mobMenubar">
            <li><Link to="/" className='linkm'><img src="image/icon/home.png" alt="Home Page" /> Home</Link></li>
            <li><Link to="about" className='linkm'><img src="image/icon/about.png" alt="About Us" /> About</Link></li>
            <li><Link to="/india" className='linkm'><img src="image/icon/india.png" alt="India Location" /> India</Link></li>
            <li><Link to="/international" className='linkm'><img src="image/icon/world.png" alt="International Location" /> International</Link></li>
            <li><Link to="/all-service" className='linkm'><img src="image/icon/corporate.png" alt="Service" /> Service</Link></li>
            <li><Link to="our-portfolio" className='linkm'><img src="image/icon/image.png" alt="Gallery Image" />Our Portfolio</Link></li>
            <li><Link to="corporate-offsite-venues-near-delhi" className='linkm'><img src="image/icon/contact.png" alt="Contact" />Corporate Offsite Near Delhi</Link></li>
            <li><Link to="corporate-offsite-venues-near-mumbai" className='linkm'><img src="image/icon/contact.png" alt="Contact" />Corporate Offsite Near Mumbai</Link></li>
            <li><Link to="contact" className='linkm'><img src="image/icon/contact.png" alt="Contact" />Contact</Link></li>
        </ul>
    </div>
</div>
  )
}

export default MobileNavBar;
